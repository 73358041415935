<template>
  <div class="context-picker-modal">
    <b-modal
        id="modal-create-context"
        ref="modal-create-context"
        footer-class="flex-nowrap"
        size="lg"
        no-close-on-backdrop
        centered
    >
      <template #modal-header>
        <h3 v-if="isElementUpdate">{{ $t('modals.createContext.updateTitle') }}</h3>
        <h3 v-else>{{ $t('modals.createContext.title') }}</h3>
      </template>

      <template #default>
        <div class="m-4">
          <div v-if="source === 'urlParameter'">
            <context-item-url-param
              :operator-options="operatorOptions"
              :selected-left-value="selectedLeftValue"
              :selected-operator="selectedOperator"
              :selected-right-value="selectedRightValue"
              @set-url-parameter="setUrlParameter"
              @trigger-create-update-context="createUpdateEntry"
            />
          </div>
          <div v-else>
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.set') }}
                </label>
              </b-col>
              <b-col cols="8">
                <div class="d-inline-block" v-if="personalizationSetData">
                  <b-form-select
                      v-model="personalizationSet"
                      :options="personalizationSetData"
                      @change="changePersonalizationSet">
                  </b-form-select>
                </div>
                <b-form-text>
                  {{ $t('modals.createContext.setDesc') }}
                </b-form-text>
              </b-col>
            </b-row>
          </div>
          <div v-if="source !== 'urlParameter'">
            <b-row class="mb-4">
              <b-col cols="4"></b-col>
              <b-col cols="8">
                <b-form-checkbox
                  id="usePersonalizationSetAsContext"
                  v-model="usePersonalizationSetAsContext"
                  name="usePersonalizationSetAsContext"
                  :value="true"
                  :unchecked-value="false"
                >
                  {{ $t('modals.createContext.usePersonalizationSetAsContext') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mb-4" v-if="!usePersonalizationSetAsContext">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.value') }}
                </label>
              </b-col>
              <b-col cols="8">
                <div class="mt-3">
                  <b-form-select
                      v-model="leftValue"
                      :options="personalizationFieldValues">
                  </b-form-select>
                  <b-form-text>
                    {{ $t('modals.createContext.valueDesc') }}
                  </b-form-text>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.operator') }}
                </label>
              </b-col>
              <b-col cols="8">
                <div class="d-inline-block">
                  <b-form-select
                      v-model="operator"
                      :options="operatorOptions">
                  </b-form-select>
                </div>
                <b-form-text>
                  {{ $t('modals.createContext.operatorDesc') }}
                </b-form-text>
              </b-col>
            </b-row>
            <b-row v-if="!usePersonalizationSetAsContext">
              <b-col cols="4">
                <label for="value-name">
                  {{ $t('modals.createContext.rightValue') }}
                </label>
              </b-col>
              <b-col cols="8">
                <b-form-input
                    id="entry-name"
                    v-model="rightValue"
                    debounce="500"
                    @keyup.enter="enterKeyPressed"
                >
                </b-form-input>
                <b-form-text>
                  {{ $t('modals.createContext.rightValueDesc') }}
                </b-form-text>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="$emit('cancel-modal')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            v-if="isElementUpdate"
            variant="primary"
            class="p-3"
            block
            :disabled="disableCreateButton"
            @click="updateEntry"
        >
          {{ $t('buttons.update') }}
        </b-button>
        <b-button
            v-else
            variant="primary"
            class="p-3"
            block
            :disabled="disableCreateButton"
            @click="createEntry"
        >
          {{ $t('buttons.create') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalCreateNewContext',
  components: {
    ContextItemUrlParam: () => import('./context/contextItemUrlParam.vue'),
  },
  props: {
    personalizationSetData: {
      type: Array,
    },
    personalizationValues: {
      type: Array,
    },
    action: {
      type: String,
    },
    orIndex: {
      type: Number,
    },
    source: {
      type: String,
    },
    isElementUpdate: {
      type: Boolean,
      default: false,
    },
    selectedLeftValue: {
      type: String,
    },
    selectedOperator: {
      type: String,
    },
    selectedRightValue: {
      type: String,
    },
  },
  data() {
    return {
      personalizationSet: null,
      leftValue: null,
      operator: null,
      operatorOptions: [
        { value: null, text: this.$t('settings.page.context.condition.operatorOptions.operator') },
        { value: '==', text: this.$t('settings.page.context.condition.operatorOptions.isEqual') },
        { value: '!=', text: this.$t('settings.page.context.condition.operatorOptions.notEqual') },
      ],
      rightValue: null,
      usePersonalizationSetAsContext: false,
    };
  },
  created() {
    if (this.isElementUpdate) {
      this.operator = this.selectedOperator;
      this.rightValue = this.selectedRightValue;
      if (this.source === 'personalization') {
        if (this.selectedLeftValue && this.selectedLeftValue !== 'personalized_variable_set_id') {
          const [setId, fieldValue] = this.selectedLeftValue.split('.');
          this.personalizationSet = this.personalizationSetData.find((set) => set.value === Number(setId))?.value;
          // Wait for computed property to update before setting leftValue
          this.$nextTick(() => {
            this.leftValue = fieldValue;
          });
        } else if (this.selectedLeftValue === 'personalized_variable_set_id') {
          this.rightValue = Number(this.selectedRightValue);
          this.leftValue = this.selectedLeftValue;
          this.personalizationSet = this.personalizationSetData.find((set) => set.value === this.rightValue).value;
          this.usePersonalizationSetAsContext = true;
        }
      } else {
        this.leftValue = this.selectedLeftValue;
      }
    }
  },
  mounted() {
    this.$refs['modal-create-context'].show();
  },
  computed: {
    disableCreateButton() {
      if (this.source === 'personalization' && this.personalizationSet === null) {
        return true;
      }
      if (this.source === 'personalization' && this.usePersonalizationSetAsContext && this.operator !== null) {
        return false;
      }
      return !this.leftValue || !this.operator || !this.rightValue;
    },
    personalizationFieldValues() {
      if (this.personalizationSet) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.leftValue = null;
        const { personalizationSet, personalizationValues } = this;
        const personalizationSetObject = personalizationValues
          .find((personalization) => personalization.setId === personalizationSet);
        if (personalizationSetObject) {
          if (!personalizationSetObject.values) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.personalizationSet = null;
          }
          return [
            { value: null, text: this.$t('settings.page.context.selectPersonalizationValue') },
            ...personalizationSetObject.values.map((field) => ({
              value: field.id,
              text: field.name,
            })),
          ];
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.personalizationSet = null;
      return [{ value: null, text: this.$t('settings.page.context.selectPersonalizationValue') }];
    },
  },
  methods: {
    setUrlParameter(urlParameter) {
      this.leftValue = urlParameter.leftValue;
      this.operator = urlParameter.operator;
      this.rightValue = urlParameter.rightValue;
    },
    createEntry() {
      if (this.usePersonalizationSetAsContext) {
        const returnObject = {
          action: this.action,
          source: this.source,
          orIndex: this.orIndex,
          leftValue: 'personalized_variable_set_id',
          operator: this.operator,
          rightValue: this.personalizationSet,
        };
        this.$emit('create-context', returnObject);
        return;
      }
      if (this.leftValue && this.operator && this.rightValue) {
        let { leftValue } = this;
        if (this.source === 'personalization') {
          leftValue = `${this.personalizationSet}.${this.leftValue}`;
        }
        const returnObject = {
          action: this.action,
          source: this.source,
          orIndex: this.orIndex,
          leftValue,
          operator: this.operator,
          rightValue: this.rightValue,
        };
        this.$emit('create-context', returnObject);
      }
    },
    changePersonalizationSet(value) {
      if (this.leftValue === 'personalized_variable_set_id') {
        this.rightValue = Number(value);
      }
    },
    createUpdateEntry() {
      if (this.isElementUpdate) {
        this.updateEntry();
      } else {
        this.createEntry();
      }
    },
    enterKeyPressed() {
      if (this.isElementUpdate) {
        this.updateEntry();
      } else {
        this.createEntry();
      }
    },
    updateEntry() {
      console.log('updateEntry weil wirs können');
      if (this.source === 'personalization' && !this.usePersonalizationSetAsContext) {
        this.leftValue = `${this.personalizationSet}.${this.leftValue}`;
      }
      const operation = {};
      operation[this.operator] = [
        { var: this.leftValue },
        this.rightValue,
      ];
      this.$emit('update-context', operation);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .modal-dialog {
  .modal-content {
    border: none;
  }

  .custom-control-input[disabled] ~ .custom-control-label {
    cursor: not-allowed;
  }
  button:disabled {
    cursor: not-allowed;
  }
}
</style>
